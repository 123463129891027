/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        $(document).on("click", "#accessibility-modal-submit", function(e) {
          e.preventDefault();
          e.stopPropagation();

          var text_size = $("input[name='accessibility-text-size']:checked").val();
          var colour_scheme = $("input[name='accessibility-colour-scheme']:checked").val();

          var data = {
            action: "update_accessibility_settings",
            text_size: text_size,
            colour_scheme: colour_scheme
          };

          $.post(ajax.url, data, function(response) {
            window.location.reload();
          });

        });
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // About
    'page_template_template_about': {
     init: function() {
      // $(".panel:not(:first-child)")
      // .find(".panel-title > a").attr("aria-expanded", false).end()
      // .find(".panel-collapse").removeClass("in");

      $.each($(".team-member-biography"), function(k, v) {
        $(this).after('<a href="#" data-more-text="Read More" data-less-text="Close" class="readmore">Read More</a>');
      });

      $(".team-member").on("click", ".readmore", function(e) {
        e.preventDefault();
        e.stopPropagation();
        var $link = $(this);
        if (!$link.prev().hasClass("more")) {
          $link.prev().addClass("more").end().text($link.data("less-text"));
        } else {
          $link.prev().removeClass("more").end().text($link.data("more-text"));
        }
      });
     }
    },
    // Events
    'page_template_template_events': {
     init: function() {
        var $cards = $(".card-list .card");
        var $filters = $(".events-filter");
        var $search = $("#events-search");

        // Filters
        $(document).on("change", ".events-filter", function(e) {
          var $select = $(this);
          var selected = $select.find("option:selected").val().toLowerCase();
          var filter = $select.parent().data("filter");
          $search.val("");
          $select.parent().siblings().find("option:selected").prop("selected", false);
          $cards.each(function(i) {
            var $card = $(this);
            var terms = $(this).data(filter);
            if (selected === "") {
              $card.closest(".col-card").show();
            } else if (terms.indexOf(selected) !== -1) {
              $card.closest(".col-card").show();
            } else {
              $card.closest(".col-card").hide();
            }
          });
        });

        // Search
        $(document).on("keyup", $search, function(e) {
          var search = $search.val().toLowerCase();
          $cards.each(function(i) {
            var $card = $(this);
            var title = $(this).data("title").toLowerCase();
            $filters.find("option:selected").prop("selected", false);
            if (search === "") {
              $card.closest(".col-card").show();
            } else if (title.indexOf(search) !== -1) {
              $card.closest(".col-card").show();
            } else {
              $card.closest(".col-card").hide();
            }
          });
        });
     }
    },
    // Resources
    'page_template_template_resources': {
      init: function() {
        $(".card-description-content").readmore({
          collapsedHeight: 60,
          blockCSS: "display: inline-block; width: auto; margin-bottom: -5px;"
        });

        var $cards = $(".card-list .card");
        var $filters = $(".tax-filter");
        var $search = $(".tax-search");

        // Filters
        $(document).on("change", ".tax-filter", function(e) {
          var $select = $(this);
          var selected = $select.find("option:selected").val().toLowerCase();
          var filter = $select.data("filter");
          $search.val("");
          $select.siblings().find("option:selected").prop("selected", false);
          $cards.each(function(i) {
            var $card = $(this);
            var terms = $(this).data(filter).toLowerCase();
            if (selected === "") {
              $card.closest(".col-card").show();
            } else if (terms.indexOf(selected) !== -1) {
              $card.closest(".col-card").show();
            } else {
              $card.closest(".col-card").hide();
            }
          });
        });

        // Search
        $(document).on("keyup", $search, function(e) {
          var search = $search.val().toLowerCase();
          $cards.each(function(i) {
            var $card = $(this);
            var title = $(this).data("title").toLowerCase();
            $filters.find("option:selected").prop("selected", false);
            if (search === "") {
              $card.closest(".col-card").show();
            } else if (title.indexOf(search) !== -1) {
              $card.closest(".col-card").show();
            } else {
              $card.closest(".col-card").hide();
            }
          });
        });
      }
    },
    // Organisations
    'page_template_template_organisations': {
      init: function() {
        var $organisations = $(".organisations-list .organisation");
        var $filter = $("#region-subject-filter");
        var $search = $("#organisations-search");

        // Filter
        $(document).on("change", $filter, function(e) {
          var $select = $(this);
          var region_selected = $select.find("option:selected").val();
          $search.val("");
          $organisations.each(function(i) {
            var $organisation = $(this);
            var region = $(this).data("region");
            if (region_selected === "") {
              $organisation.closest(".organisation").show();
            } else if (region === region_selected) {
              $organisation.closest(".organisation").show();
            } else {
              $organisation.closest(".organisation").hide();
            }
          });
        });

        // Search
        $(document).on("keyup", $search, function(e) {
          var search = $search.val().toLowerCase();
          $organisations.each(function(i) {
            var $organisation = $(this);
            var $strings = $organisation.find(".organisation-search-data");
            var string_found = false;

            $.each($strings, function(index, value) {
              var $string = $(this);
              if ($string.text().indexOf(search) !== -1) {
                string_found = true;
              }
            });

            if (search === "") {
              $organisation.show();
            } else if (string_found) {
              $organisation.show();
            } else {
              $organisation.hide();
            }
          });
        });
      }
    },
    'tax_standard': {
      init: function() {
        $(".card-description-content").readmore({
          collapsedHeight: 80,
          blockCSS: "display: inline-block; width: auto; margin-bottom: -5px;"
        });
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
